
@import '../../../variable.scss';


.propertyBox{

    display: grid;
    align-items: center;
    align-content: start;
    grid-row-gap: 0.25rem;
    background-color: $appBackgroundColorOffWhite;
    text-align: left;
    padding: 1rem !important;
    height: calc(100vh - 80px - 0.5rem - 1rem - 34px - 2rem);
    max-height: calc(100vh - 80px - 0.5rem - 1rem - 34px - 2rem);
    overflow: auto;
}