@import '../../../variable.scss';


.toolList {

    background-color: $appBackgroundColorOffWhite;
    display: flex;
    height: fit-content;
    padding: 0.5rem;



    .tool{
        border-right: 1px solid rgba(53, 53, 53, 0.5);
        padding: 5px;

        &:hover{
            background-color: darken($appBackgroundColorOffWhite, 2%);
            cursor: pointer;
        }
    }
    svg{
        height: 24px;
        width: 24px;
    }
}