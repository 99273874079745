@import '../variable.scss';

.App {
  text-align: center;
  color: $primaryTextColor;
  background-color: $appBackgroundColor;
  // overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
 label, input, button{
  font-family: 'Poppins', sans-serif;
 }
}

.logo{
  &:hover{
    cursor: pointer;
  }
}

.dom-close-button{
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 100000000000000000000;
}


.p-toolBar, .toolBar {
  flex: 0 1 auto;

  img{
    height: $s6;
  }

  .p-toolbar-group-right {
    display: flex;
    align-content: center;
    align-items: center;
  }
}

.mainSection {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  flex: 1 1 auto;
}


/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #535353;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer;
}


.rightMenu {
  display: grid;
  grid-template-rows: auto 1fr;
  z-index: 1000;
}




//button styling

.MuiButton-contained {
  background-color: $primaryColor;
}

.input {
  width: 100%;
}

input,
select,
textarea {
  padding: 0.5rem 0.25rem;
  background-color: $appBackgroundColor;
  color: white;
  border-radius: 5px;
  box-shadow: 0 0 1px $cardShadowColour;
  height: 40px;
  width: 100%;
  max-width: 100%;
  overflow: ellipse;
  border: unset;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}


textarea {
  height: 100px;
}




// typography

.bigHeading {
  font-size: $bigHeadingLarge  !important;
  font-family: "Montserrat", sans-serif !important;
}

.header {
  font-size: $headingLarge  !important;
  font-family: "Montserrat", sans-serif !important;
}

.no-margin {
  margin: 0;
}

.headerSmall {
  font-size: $headingSmall  !important;
  font-family: "Montserrat", sans-serif !important;
}

.subtitle1 {
  font-size: $subtitle1Large  !important;
  font-family: "Nunito", sans-serif !important;
  font-weight: 600 !important;
}

.subtitle2 {
  font-size: $subtitle2Large  !important;
  font-family: "Nunito", sans-serif !important;
  font-weight: 600 !important;
}

.body {
  font-size: $bodyLarge  !important;
  font-family: "Nunito", sans-serif !important;
  font-weight: 600 !important;
}

.bodyLight {
  font-size: $bodyLarge  !important;
  font-family: "Nunito", sans-serif !important;
}

.subinfo {
  font-size: $subinfoLarge  !important;
  font-family: "Nunito", sans-serif !important;
}




.doubleSpan{
  grid-template-columns: span 2;
}