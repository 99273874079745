@import '../../../variable.scss';

.Signup{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 $s1;

    form{
        display: grid;
        align-items: center;
        gap: $s2;
        min-width: calc($s1 * 35);
        justify-items: center;

        &>*:not(img){
            min-width: 100%;
        }

        h1{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: $s1;
        }
    }

}

.accountType{
    display: flex;
    gap: $s1;
}