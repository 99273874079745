@import '../../../variable.scss';

.AddProjectForm{

    .p-card-content{
        display: grid;
        gap: $s2;

    }


    

    .buttonContainer{
        display: flex;
        gap: $s1;
    }
}