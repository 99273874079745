/* variables */
$primaryColor: #e34623;
$primaryColorGradientCompliment: #fe8501;
$primaryColorFaded: #fda03c;
// $primaryColorFaded: #ffbd76;


$secondaryColor: #3495d6;
$secondaryColorOffset: #97d5ff;
$tertiaryColor: #343e8e;
$tertiaryColorWhiteOffSet: #eae9ff;
$tertiaryColorDarkOffSet: #273070;

$tertiaryColorOffset: #e3e6ff;

$appBackgroundColor: #1e1e1e;
$appBackgroundColorOffWhite: #222222;
$primaryTextColor: rgb(240, 240, 240);
$secondaryTextColor: rgb(230, 230, 230);


$--surface-a: #1e1e1e;

$panelColor: $--surface-a;

// font size variables
$bigHeadingLarge: 48px;
$bigHeadingMedium: 32px;
$bigHeadingSmall: 32px;

$headingLarge: 32px;
$headingMedium: 24px;
$headingSmall: 18px;

$subtitle1Large: 24px;
$subtitle1Medium: 18px;
$subtitle1Small: 16px;

$subtitle2Large: 18px;
$subtitle2Medium: 16px;
$subtitle2Small: 16px;

$bodyLarge: 16px;
$bodyMedium: 16px;
$bodySmall: 16px;

$subinfoLarge: 14px;
$subinfoMedium: 12px;
$subinfoSmall: 10px;

//testimonial stylings
$testimonialBackGround: #fff;

//side bar styles
$sideBarWidth: 350px;

$cardShadowColour: rgba(255, 255, 255, 0.514);






//breakpoints
$fourkscreen: 1800px;
$largeScreen:1200px;
$tabletScreen: 768px;
$mobileScreen: 375px;



//spacing
$s1: 8px;
$s2: 16px;
$s3: 24px;
$s4: 32px;
$s5: 40px;
$s6: 48px;
$s7: 56px;
$s8: 64px;
$s9: 72px;
$s10: 80px;
