@import '../../../variable.scss';

.Signin{
    min-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 $s1;
    position: relative;

    form{
        display: grid;
        align-items: center;
        gap: $s2;
        min-width: calc($s1 * 35);
        justify-items: center;

        &>*:not(img){
            min-width: 100%;
        }

        h3{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: $s1;
        }

        .button-container{
            gap: $s1;

            button{
                flex: 1 auto;
                width: 50%;
            }
        }
    }

    .wave{
        position: absolute;
        left: - calc($s1 * 15);
        transform:rotateZ(190deg);
        top: 20%;
        width: 45vw;
    }

    .wave2{
        position: absolute;
        right: - calc($s1 * 20);
        width: 50vw;
    }

    .coming-soon-banner{
        position: absolute;
        bottom: $s2;
        right: $s2;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $s2;
    }

}



.accountType{
    display: flex;
    gap: $s1;
}


@media only screen and (max-width: 768px) {
    .wave,.wave2{
        display: none;
    }

    .coming-soon-banner{
        position: absolute;
        bottom: $s2;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $s2;
    }
}