.canvasContainer {
    display: grid;
    align-content: center;
    justify-content: center;
    width: 60vw;
    height: calc(100vh - 100px - 20px);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: auto;
    padding: 1rem;

    ::-webkit-scrollbar {
        display: none;
    }

    div{
        overflow: auto;
    }
    * {

        position: relative !important;
    }

}