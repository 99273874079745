@import "../../../variable.scss";

.Dashboard {
    .logo {
        height: $s6;
    }

    .p-inputgroup {
        .p-inputtext {
            height: unset;
        }
    }

    .toolbar-right-section{
        &>*{
            flex: 1 auto;
        }
    }


    .projectSection {
        padding: $s4 $s2;

        .projectsContainer,
        .templatesContainer {
            display: flex;
            flex-wrap: wrap;
            gap: $s2;
            transition: all 0.25s;

            .projectBox {
                height: calc($s1 * 25);
                width: calc($s1 * 25);
                font-weight: 600;
                display: flex;
                flex: 1 auto;
                max-width: 300px;
                min-width: 250px;
                justify-content: center;
                align-items: center;
                transition: all 0.25s;
                border-radius: $s1;
                position: relative;

                &:hover {
                    cursor: pointer;
                    transform: scale(1.05);
                }

                .footer {
                    position: absolute;
                    padding: $s1 $s1;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: $s8;
                    display: flex;
                    justify-content: space-between;
                    gap: $s1;
                    align-items: center;
                    background-color: rgb(34, 34, 34);
                    border-radius: 0 0 calc($s1 - 1px) calc($s1 - 1px);

                    .project-details {
                        display: grid;
                        gap: $s1/2;

                        .last-edited{
                            font-weight: normal;
                            color: darken($color: #fff, $amount: 40);
                        }
                    }



                }
            }
        }
    }
}


@media screen and (max-width: $tabletScreen) {

    .Dashboard {
        .projectSection {


            .projectsContainer,
            .templatesContainer {
                .projectBox {
                    max-width: unset;
                }
            }
        }
    }
}