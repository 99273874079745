@import '../../../variable.scss';

.Landing {
    display: flex;
    flex-direction: column;

    .landingBackground{
        background: url('../../../public/shape_background.svg');
        background-size: contain;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        mix-blend-mode: lighten;
        opacity: 0.75;
        background-repeat: no-repeat;
    }

    .p-toolbar {
        border-bottom: none;
        z-index: 1;
        background-color: unset;
    }

    .logo {
        height: $s8;
    }

    .mainSection {
        min-height: 90vh;
        padding: $s8;

        display: grid;
        grid-template-columns: 1fr 1fr;

        .leftSection {
            display: grid;
            align-content: center;
            text-align: left;
            z-index: 2;
            gap: $s3;

            &>* {
                position: relative;
                left: calc($s1 * 20);
            }

            h1,
            p {
                font-size: calc($s1 * 10);
                font-weight: 900;
                margin: 0;
            }

            p {
                font-size: $s8;
            }

            span {
                font-size: $s4;
            }

            button {
                width: 250px;
                background: rgb(79, 232, 208);
                background: linear-gradient(90deg, rgba(79, 232, 208, 1) 0%, rgba(151, 71, 255, 1) 48%, rgba(255, 214, 0, 1) 100%);
                color: white;
    
                span {
                    font-size: $s3;
                }
            }
        }

        .rightSection {
            position: relative;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            min-height: 500px;

            video {
                border-radius: $s2;
                position: absolute;
                width: 100%;
                left: -2px;
            }
        }


    }
}


@media screen and (max-width: $largeScreen) {
    .Landing {
        .mainSection {
    
            .leftSection {
    
                &>* {
                    left: calc($s1 * 10);
                }
            }
        }
    }
}


@media screen and (max-width: 1100px) {
    .Landing {
        .mainSection {
            grid-template-columns: 1fr;

            .leftSection {
    
                &>* {
                    left: unset;
                }
            }
        }
    }
}


@media screen and (max-width: 575px) {
    .Landing {
        .logo {
            height: $s4;
        }
        .mainSection {
            grid-template-columns: 1fr;

            .leftSection {
    
                h1{
                    font-size: $s8;
                }
            }
        }
    }
}