@import '../../../variable.scss';

.propertyGroupBox{

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    align-items: start;
    align-content: start;
    grid-row-gap: 1rem;
    text-align: left;
    overflow: auto;

    label{
        display: block;
        margin-bottom: 0.5rem;
    }

    .p-colorpicker-preview{
        height: 40px;
        width: 40px;
    }

    
    .doubleSpan{
        grid-column-start: span 2;
    }
}