@import '../../../variable.scss';

.leftMenu {
    display: grid;

    .MuiDrawer-paper {
        background-color: $appBackgroundColorOffWhite;
        color: white;
        
        display: grid;
        grid-row-gap: 1rem;
        align-content: start;
        position: relative;
        max-height: calc(100vh - 0.5rem);
        text-align: left;
        &>*{
            padding: $s2;
        }

        .addPageSection{

            border-bottom: 0.5px solid rgba(255,255,255,0.25);
            display: grid;
            grid-row-gap: 0.5rem;

            .form{
                display: flex;
                column-gap: 0.5rem;
            }

            .p-button{
                font-size: 0.75rem !important;
                padding: 0.5rem;
            }
            *{
                max-height: 80px !important;

           
            }
        }

    }

    .p-toast-message-icon {
        display: none !important;
    }
}