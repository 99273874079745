@import '../../../variable.scss';

.leftMenuList {

    max-height: 600px;
    overflow: auto;
    display: grid;
    grid-row-gap: 0.5rem;
    .MuiTreeItem-root {
        padding: 0.5rem;
        border-radius: 10px;

        &[aria-selected="true"] {
            background-color: $primaryColor;
        }

        .MuiTreeItem-content {
            &.Mui-selected {
                background-color: unset;
            }
        }
    }


}


.treeNode {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .buttonGroup{
        display: flex;
        align-content: center;
        justify-content: flex-end;
        column-gap: 0.5rem;
    }

    button{
        width: 28px !important;
        height: 28px;
        padding: 0.25rem !important;
    }

    .p-treenode-content{
        padding: 0 !important;
    }
}