@import '../../../variable.scss';


.Home {
    background-color: $appBackgroundColor;
    min-height: 100vh;
}

.swal2-popup {
    background-color: $panelColor !important;
    color: white !important;
}


.p-toolbar {
    background-color: $panelColor;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.logo {
    max-width: 80%;
}


.checkbox-group {
    display: flex;
    align-items: center;
    gap: $s1;
}



@keyframes pseudoAnimated {
    0% {
        bottom: -20px;
        transform: rotateZ(5deg);
    }

    100% {
        bottom: 0;
        transform: rotateZ(0);
    }
}

@keyframes pseudoAfterAnimation {
    0% {
        height: 100%;
    }

    100% {
        height: 0%;
    }
}



.pseudo-after-animated {
    position: relative;
    animation-name: pseudoAnimated;
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    animation-duration: 1s;
    animation-delay: 0.5s;


    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $appBackgroundColor;
        animation-name: pseudoAfterAnimation;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-delay: 0.5s;
        animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &:after {
        &::before {
            width: 100%;
        }
    }
}